import { get, post, put, destroy } from './';

export const postKnowledge = payload => post(`/api/v1/knowledges`, payload);

export const getKnowledges = ({ params }) =>
  get(`/api/v1/knowledges`, { params });

export const getKnowledge = ({ knowledgeId, params }) =>
  get(`/api/v1/knowledges/${knowledgeId}`, { params });

export const updateKnowledge = (knowledgeId, payload) =>
  put(`/api/v1/knowledges/${knowledgeId}`, payload);

export const deleteKnowledge = knowledgeId =>
  destroy(`/api/v1/knowledges/${knowledgeId}`);

export const getCategories = () => get(`/api/v1/categories`);

export const getChildrenGroups = () => get(`/api/v1/children_groups`);

export const getChildrenGroup = ({ groupId }) =>
  get(`/api/v1/children_groups/${groupId}`);

export const getChildrenGroupReports = ({ sliceId, params }) =>
  get(`/api/v1/children_groups/${sliceId}/reports`, { params });

export const postChildrenGroupReport = (groupId, payload) =>
  post(`/api/v1/children_groups/${groupId}/reports`, payload);

export const getReport = ({ reportId }) => get(`/api/v1/reports/${reportId}`);

export const deleteReport = reportId => destroy(`/api/v1/reports/${reportId}`);

export const updateReport = (reportId, payload) =>
  put(`/api/v1/reports/${reportId}`, payload);

export const sendContactMail = params => post('/api/v1/contact', params);

export const uploadAttachment = payload =>
  post(`/api/v1/uploads/files`, payload);

export const deleteVolunteerMessage = messageId =>
  destroy(`/api/v1/messages/${messageId}`);

export const updateMessage = (messageId, payload) =>
  put(`/api/v1/messages/${messageId}`, payload);
