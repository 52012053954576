import { Form, TextField, ErrorMessageWrapper } from 'components/Forms';
import { Formik } from 'formik';
import { Buttons } from 'ui';
import * as Yup from 'yup';
import { ERRORS } from 'utils';
import { Button } from 'components';

const SignupForm = ({ onSubmit }) => {
  const signupFormSchema = Yup.object().shape({
    firstName: Yup.string().required(ERRORS.REQUIRED),
    lastName: Yup.string().required(ERRORS.REQUIRED),
    password: Yup.string().required(ERRORS.REQUIRED),
  });

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={signupFormSchema}
      initialValues={{
        firstName: '',
        lastName: '',
        password: '',
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <TextField name="firstName" label="Prénom" />

          <TextField name="lastName" label="Nom" />

          <TextField name="password" label="Mot de passe" type="password" />

          {Boolean(errors.invite) && (
            <ErrorMessageWrapper style={{ marginBottom: 16 }}>
              Le code d’activation n’est pas valide ou a déjà été utilisé.
            </ErrorMessageWrapper>
          )}

          <Buttons style={{ marginTop: 20 }}>
            <Button variant="primary" isLoading={isSubmitting}>
              Activer
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
