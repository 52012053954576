import { useContext } from 'react';
import {
  DataContext,
  AuthContext,
  SnacksContext,
  DraftContext,
  WidgetContext,
} from 'contexts';

export const useData = () => {
  const context = useContext(DataContext);

  if (context === undefined) {
    throw new Error(`useData must be used within a DataProvider`);
  }

  return context;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};

export const useSnacks = () => {
  const context = useContext(SnacksContext);

  if (context === undefined) {
    throw new Error(`useSnacks must be used within a SnacksProvider`);
  }

  return context;
};

export const useDraft = () => {
  const context = useContext(DraftContext);

  if (context === undefined) {
    throw new Error(`useDraft must be used within a DraftProvider`);
  }

  return context;
};

export const useWidget = () => {
  const context = useContext(WidgetContext);

  if (context === undefined) {
    throw new Error(`useWidget must be used within a WidgetProvider`);
  }

  return context;
};
