import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { spacing, List, Container, media, fontSize } from 'ui';
import { HamburgerElastic } from 'react-animated-burgers';
import { ButtonReset } from '@tymate/margaret';

export const Page = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.background};
  min-height: 100vh;
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  ${'' /* height: 96px; */}

  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  padding-top: ${spacing()};
  ${'' /* padding-bottom: ${spacing()}; */}
  z-index: 11;

  ${media.tablet`
    z-index: 2;
  `}

  > ${Container} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${props =>
    props.variant === 'shaded' &&
    css`
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
    `}

  ${props =>
    props.app === 'traveler' &&
    css`
      color: ${({ theme }) => theme.text};
      background-color: ${({ theme }) => theme.yin};
      background-image: none;
      z-index: 1;
      padding-top: ${({ theme }) => theme.spacing(0.5)};
    `}
`;

export const LogOutButton = styled(ButtonReset)`
  padding: ${spacing()};
  color: ${({ theme }) => theme.secondary};
  font-weight: 600;
`;

export const HeaderTitle = styled.h1`
  font-size: 1.5em;
  font-weight: 500;
`;

export const FooterWrapper = styled.footer`
  width: 100%;
  min-height: 160px;
  box-shadow: 0 -1px 0 0 ${({ theme }) => theme.boxShadowColor};
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.yin};
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

export const MainNav = styled(List)`
  display: none;

  ${props =>
    props.isShown &&
    css`
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      width: 250px;
      bottom: 0;
      background-color: ${({ theme }) => theme.mobileNavBackground};
      z-index: 1;
      padding-top: 76px;
    `}

  ${media.tablet`
    padding-top: 0;
    position: relative;
    background-color: transparent;
    display: flex;
    flex: 1;
    margin-left: ${({ theme }) => theme.spacing(-2)};
    padding-left: ${({ theme }) => theme.spacing()};
  `}
`;

export const MainNavBackdrop = styled(ButtonReset)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  width: 100%;

  ${props =>
    props.isShown &&
    css`
      background-color: rgba(0, 0, 0, 0.4);
      display: block;
      transition: background-color 100ms ease;
    `}

  ${media.tablet`
    display: none;
  `}
`;

export const MainNavItem = styled.li`
  margin-left: ${({ theme }) => theme.spacing()};

  ${media.desktop`
    margin-left: ${({ theme }) => theme.spacing(2)};
  `}

  ${props =>
    props.onlyShowOnSmallViewports &&
    css`
      ${media.tablet`
      display: none;
    `}
    `}
`;

export const MainNavItemLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
  position: relative;
  color: ${({ theme }) => theme.text};
  ${fontSize.body}

  ${media.tablet`
    ${fontSize.body}
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);

    &.active:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      border-bottom: 4px solid ${({ theme }) => theme.primary};
    }
  `}

  ${props =>
    props.app === 'traveler' &&
    css`
      color: ${({ theme }) => theme.text};
      text-shadow: none;

      ${media.tablet`
        padding-top: ${({ theme }) => theme.spacing(2)};
        padding-bottom: ${({ theme }) => `calc(${theme.spacing(2)} - 4px)`};
        color: inherit;
        text-shadow:none;
      `}
    `}

  ${({ fontFamily }) =>
    Boolean(fontFamily) &&
    css`
      font-family: ${fontFamily}, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    `}
`;

export const HeaderLogo = styled.img`
  display: block;
  width: 48px;
  height: auto;

  ${media.tablet`
    width: auto;
    height: 76px;
  `}
`;

export const HeaderLogoLink = styled(NavLink)`
  display: block;
  position: relative;
  z-index: 2;
`;

export const VolunteerPage = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.travelerBackground};
`;

export const TravelerPage = styled(VolunteerPage)`
  padding-top: 64px;
  background-color: ${({ theme }) => theme.travelerBackground};

  ${media.tablet`
    padding-top: 92px;
  `}
`;

export const PageContent = styled.div`
  flex: 1;
`;

export const MainNavDismiss = styled(ButtonReset)`
  position: fixed;
  z-index: 13;
  color: ${({ theme }) => theme.text};
  right: 0;
  padding: ${({ theme }) => theme.spacing()};
  ${fontSize.h3}

  ${media.tablet`
    display: none;
  `};
`;

export const MainNavMobileTrigger = styled(HamburgerElastic)`
  color: #fff;
  margin-left: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    display: none;
  `};
`;

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
