import { spacing } from './';

export const colors = {
  ui: {
    text: '#464646',
    textLight: 'rgba(0, 0, 0, 0.38)',
    background: '#f1edf2',
    backgroundLight: '#f1edf2',
    backgroundDark: 'rgb(18, 18, 18)',
    travelerBackground: '#f1f1f1',
    snackBackground: '#333',

    boxShadowColor: 'rgba(0, 0, 0, 0.12)',
    boxShadowColorHover: 'rgba(0, 0, 0, 0.18)',

    separatorLight: 'rgba(0, 0, 0, 0.12)',
    separator: 'rgba(0, 0, 0, 0.24)',
    separatorDark: 'rgba(0, 0, 0, 0.34)',
    mainGradient: 'linear-gradient(15deg, #502159, #391543)',
    primary: '#F39600',
    primaryLight: '#FFA71A',
    secondary: '#D2D213',
    main: '#F1F1F1',
    orange: '#F39600',
    red: '#F14C66',
    green: '#D2D213',
    blue: '#89C1E9',
    darkBlue: '#1964B5',
    error: '#F14C66',
    success: '#bada55',
    borderRadius: '6px',
    yin: '#fff',
    mobileNavBackground: '#fff',
    disabled: 'rgba(0,0,0,0.12)',
    iconLight: 'rgba(0, 0, 0, 0.38)',
    selectionColor: '#FFFF26',
    readHelperSelectionColor: '#FFFF26',
    selectedTextColor: 'rgb(18, 18, 18)',
    tag: 'rgba(0, 0, 0, 0.6)',
  },
};

export const theme = {
  spacing,

  button: {
    transition: 'background 150ms ease',
    borderRadius: '99px',
    alignY: 'center',
    alignX: 'center',
    fontWeight: '600',
    paddingVertical: spacing(0.5),
    paddingHorizontal: spacing(),
    minWidth: '120px',
    color: '#F39600',
    colorDisabled: 'rgba(0, 0, 0, 0.38)',
    backgroundHover: '#f1edf2',

    primary: {
      background: '#F39600',
      color: '#fff',
      boxShadow: 'none',
      paddingVertical: spacing(0.75),
      paddingHorizontal: spacing(1.5),

      backgroundHover: '#FFA71A',

      backgroundDisabled: 'rgba(0, 0, 0, 0.12)',
      colorDisabled: 'rgba(0, 0, 0, 0.38)',
      boxShadowDisabled: '0 0 0',
    },

    outline: {
      paddingVertical: spacing(0.5),
      paddingHorizontal: spacing(),
      backgroundHover: 'transparent',
      backgroundDisabled: 'rgba(0, 0, 0, 0.12)',
      colorDisabled: 'rgba(0, 0, 0, 0.38)',
    },

    square: {
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.24)',
      paddingVertical: spacing(0.5),
      paddingHorizontal: spacing(),
      borderRadius: '4px',
      background: '#fff',
      backgroundHover: '#fff',
      colorHover: 'rgba(0, 0, 0, 0.38)',
      boxShadowHover: 'inset 0 0 0 1px rgba(0, 0, 0, 0.38)',
    },
  },
};

export const darkColors = {
  ui: {
    text: 'rgb(255, 255, 255)',
    textLight: 'rgba(255, 255, 255, 0.80)',
    travelerBackground: 'rgb(40, 40, 40)',
    background: 'rgb(18, 18, 18)',
    backgroundLight: 'rgb(40, 40, 40)',
    backgroundDark: 'rgb(18, 18, 18)',
    snackBackground: '#f1f1f1',

    boxShadowColor: 'rgba(255, 255, 255, 0.1)',
    boxShadowColorHover: 'rgba(255, 255, 255, .16)',

    separatorLight: 'rgb(62, 62, 62)',
    separator: 'rgb(196, 196, 196)',
    separatorDark: 'rgb(40, 40, 40)',
    mainGradient: 'linear-gradient(15deg, #502159, #391543)',
    primary: '#F39600',
    primaryLight: '#FFA71A',
    secondary: '#D2D213',
    main: '#F1F1F1',
    orange: '#F39600',
    red: '#F14C66',
    green: '#D2D213',
    blue: '#89C1E9',
    darkBlue: '#1964B5',
    error: '#F14C66',
    success: '#1964B5',
    borderRadius: '6px',
    yin: 'rgb(18, 18, 18)',
    mobileNavBackground: 'rgb(18, 18, 18)',
    iconLight: 'rgba(0, 0, 0, 0.38)',
    selectionColor: '#FFFF26',
    readHelperSelectionColor: '#1B4F72 ',
    selectedTextColor: 'rgb(18, 18, 18)',
    tag: 'rgba(0, 0, 0, 0.6)',
  },
};

export const darkMapTheme = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8ec3b9',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1a3646',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4b6878',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#64779e',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4b6878',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#334e87',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#283d6a',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#6f9ba5',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3C7680',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#304a7d',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#98a5be',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#2c6675',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#255763',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#b0d5ce',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#98a5be',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#283d6a',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3a4762',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#0e1626',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#4e6d70',
      },
    ],
  },
];
