import { TextLink, Buttons } from 'ui';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, TextField, ErrorMessageWrapper } from '.';
import { ERRORS } from 'utils';
import { Button } from 'components';

const LoginForm = ({ onSubmit }) => {
  const LoginFormSchema = Yup.object().shape({
    username: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
    password: Yup.string().required(ERRORS.REQUIRED),
  });

  return (
    <Formik
      validationSchema={LoginFormSchema}
      initialValues={{ username: '', password: '' }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <TextField label="Email" type="email" name="username" />
          <TextField
            label="Mot de passe"
            type="password"
            name="password"
            secondaryLink={
              <TextLink
                variant="subtle"
                style={{
                  display: 'inline-block',
                  marginBottom: 8,
                }}
                to="/mot-de-passe-oublie"
                tabIndex="1"
              >
                Mot de passe oublié ?
              </TextLink>
            }
          />

          {status === 'invalid_grant' && (
            <ErrorMessageWrapper>
              L’adresse email et le mot de passe que vous avez entrés ne
              correspondent pas à ceux présents dans nos fichiers. Veuillez
              vérifier et réessayer.
            </ErrorMessageWrapper>
          )}
          <Buttons hasTopMargin>
            <Button
              variant="primary"
              size="full"
              isLoading={isSubmitting}
              type="submit"
            >
              Se connecter
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
