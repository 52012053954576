import AuthLayout from 'components/AuthLayout';
import { Form, TextField, ErrorMessageWrapper } from 'components/Forms';
import { Formik } from 'formik';
import { updatePassword } from 'api/auth';
import { Buttons } from 'ui';
import queryString from 'querystring';
import * as Yup from 'yup';
import { ERRORS } from 'utils';
import { useAuth } from 'hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { Button } from 'components';

const updatePasswordFormSchema = Yup.object().shape({
  password: Yup.string().required(ERRORS.REQUIRED),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], ERRORS.PASSWORDS_DO_NOT_MATCH)
    .required(ERRORS.REQUIRED),
});

const UpdatePasswordForm = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={updatePasswordFormSchema}
    initialValues={{ password: '', passwordConfirmation: '' }}
  >
    {({ isSubmitting, status }) => (
      <Form>
        <TextField
          name="password"
          label="Nouveau mot de passe"
          type="password"
        />

        <TextField
          name="passwordConfirmation"
          label="Confirmation du nouveau mot de passe"
          type="password"
        />

        {Boolean(status) && (
          <ErrorMessageWrapper style={{ marginBottom: 32 }}>
            {status}
          </ErrorMessageWrapper>
        )}

        <Buttons>
          <Button variant="primary" isLoading={isSubmitting}>
            Continuer
          </Button>
        </Buttons>
      </Form>
    )}
  </Formik>
);

const UpdatePassword = () => {
  const { search } = useLocation();
  const { onUpdate, accessToken } = useAuth();
  const confirmationToken = queryString.parse(search)?.['?token'];

  const handleUpdatePassword = async (values, { setSubmitting }) => {
    try {
      const { data } = await updatePassword(confirmationToken, {
        password: values.password,
      });
      const { accessToken, refreshToken } = data;

      setSubmitting(false);
      onUpdate({ accessToken, refreshToken });
    } catch (err) {
      setSubmitting(false);
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout title="Nouveau mot de passe">
      <UpdatePasswordForm onSubmit={handleUpdatePassword} />
    </AuthLayout>
  );
};

export default UpdatePassword;
