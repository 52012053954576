import { useState } from 'react';
import { WidgetContext } from 'contexts';

const LIGHT = 'LIGHT';
const DARK = 'DARK';

const WidgetProvider = ({ children }) => {
  const [size, setSize] = useState(0);
  const [mode, setMode] = useState(
    Boolean(localStorage.getItem('dark')) ? DARK : LIGHT,
  );
  const [readHelper, setReadHelper] = useState(
    Boolean(localStorage.getItem('readHelper')) ? true : false,
  );
  const [fontName, setFontName] = useState(
    localStorage.getItem('fontName') || 'Open Sans',
  );

  const getNextFontName = () => {
    switch (fontName) {
      case 'OpenDyslexic':
        return 'Arial';
      case 'Arial':
        return 'Open Sans';
      case 'Open Sans':
      default:
        return 'OpenDyslexic';
    }
  };

  const incrementSize = () => {
    setSize(Math.min(size + 1, 3));
  };

  const decrementSize = () => {
    setSize(Math.max(size - 1, -2));
  };

  const toggleMode = () => {
    const nextMode = mode === LIGHT ? DARK : LIGHT;

    if (nextMode === DARK) {
      localStorage.setItem('dark', 'true');
    } else {
      localStorage.removeItem('dark');
    }

    setMode(nextMode);
  };

  const toggleReadHelper = () => {
    const nextMode = !readHelper;

    if (nextMode) {
      localStorage.setItem('readHelper', 'true');
    } else {
      localStorage.removeItem('readHelper');
    }

    setReadHelper(nextMode);
  };

  const toggleFont = () => {
    const nextFontName = getNextFontName();
    localStorage.setItem('fontName', nextFontName);
    setFontName(nextFontName);
  };

  const handleSetFont = fontName => {
    localStorage.setItem('fontName', fontName);
    setFontName(fontName);
  };

  const handleReset = () => {
    setFontName('Open Sans');
    setSize(0);
  };

  return (
    <WidgetContext.Provider
      value={{
        size,
        incrementSize,
        decrementSize,
        mode,
        toggleMode,
        readHelper,
        toggleReadHelper,
        toggleFont,
        setFont: handleSetFont,
        fontName,
        nextFontName: getNextFontName(),
        onReset: handleReset,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};

export default WidgetProvider;
