import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MargaretProvider } from '@tymate/margaret';
import AuthProvider from 'providers/AuthProvider';
import DataProvider from 'providers/DataProvider';
import Spinner from 'components/Spinner';
import { colors, theme, darkColors } from 'ui/theme';
import { useWidget } from 'hooks';
import WidgetProvider from 'providers/WidgetProvider';
import * as serviceWorker from './serviceWorker';
import GlobalStyles from 'components/GlobalStyles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN } from './constants';
import ResetPassword from 'containers/Auth/ResetPassword';
import Signup from 'containers/Auth/Signup';
import ForgottenPassword from 'containers/Auth/ForgottenPassword';
import Login from 'containers/Auth/Login';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import 'rc-slider/assets/index.css';
import './index.css';

const App = lazy(() => import('./App'));

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: 'kolibri@' + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      // cacheTime: 1000 * 60 * 60 * 24 * 2,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

const Content = () => {
  const { size, mode, fontName, readHelper } = useWidget();

  return (
    <MargaretProvider
      theme={{ ...theme, size, fontName }}
      colors={mode === 'LIGHT' ? colors : darkColors}
    >
      <>
        <GlobalStyles isReadHelper={readHelper} />

        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <DataProvider>
              <Routes>
                <Route
                  path="reinitialiser-mon-mot-de-passe"
                  element={<ResetPassword />}
                />
                <Route
                  path="mot-de-passe-oublie"
                  element={<ForgottenPassword />}
                />
                <Route path="connexion" element={<Login />} />
                <Route path="activer-mon-compte" element={<Signup />} />
                <Route path="*" element={<App />} />
              </Routes>
            </DataProvider>
          </AuthProvider>
        </QueryClientProvider>
      </>
    </MargaretProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Suspense fallback={<Spinner />}>
    <Sentry.ErrorBoundary fallback={'An error has occured'}>
      <BrowserRouter>
        <WidgetProvider>
          <Content />
        </WidgetProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </Suspense>,
);

serviceWorker.unregister();
