import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { spacing, media, fontSize } from 'ui';
import { ErrorMessage as RawErrorMessage } from 'formik';
import { ButtonReset } from '@tymate/margaret';

export const FormField = styled.div`
  ${props =>
    props.variant === 'message' &&
    css`
      flex: 1;
    `};

  ${props =>
    props.icon &&
    css`
      position: relative;
    `};

  + * {
    margin-top: ${spacing()};
  }
`;

export const FormLabel = styled.label`
  display: block;
  position: relative;
  margin-bottom: ${spacing(0.5)};
  ${fontSize.body}
  font-weight: 600;

  ${({ hasBareMargin }) =>
    hasBareMargin &&
    css`
      margin: 0;
    `}

  ${props =>
    props.variant === 'emphasized' &&
    css`
      font-weight: 600;
    `}

  ${props =>
    props.hasError &&
    css`
      color: ${({ theme }) => theme.error};
    `}
`;

export const FormSublabel = styled.p`
  font-size: 0.825em;
  margin-top: 0;
  margin-bottom: 0;

  ${({ hasBareMargin }) =>
    hasBareMargin &&
    css`
      margin: 0;
    `}

  ${props =>
    props.variant === 'error' &&
    css`
      color: ${({ theme }) => theme.error};
    `}
`;

export const InputIcon = styled.div`
  position: absolute;
  left: 14px;
  top: 44px;

  svg {
    display: none;
    font-size: 1.875em;
    color: ${({ theme }) => theme.mainInactive};
    height: 40px;

    ${media.tablet`
        display: block;
      `}
  }
`;

export const Input = styled.input`
  display: inline-block;
  width: 100%;
  background-color: ${({ theme }) => theme.yin};
  padding: ${spacing(0.5)} ${spacing()};
  border: 2px solid ${({ theme }) => theme.separator};
  border-radius: 6px;
  color: inherit;
  outline: none;
  font-size: 1.25em;
  font-weight: 400;
  height: 57px;

  ${({ icon }) =>
    icon &&
    css`
      ${media.tablet`
        padding-left: ${spacing(4)};
      `}
    `};

  ${({ iconRight }) =>
    iconRight &&
    css`
      padding-right: ${spacing(4)};
    `};

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.separatorDark};

    + ${InputIcon} svg {
      color: ${({ theme }) => theme.separatorDark};
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.backgroundDisabled};
    cursor: not-allowed;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.error};

        + ${InputIcon} svg {
          color: ${({ theme }) => theme.error};
        }
      }
    `}

  ${({ variant, theme }) =>
    variant === 'subtle' &&
    css`
      border: 1px solid ${theme.separator};
      padding: ${spacing(0.5)} ${spacing()};
      height: auto;
      font-size: 1rem;
    `}
`;

export const Textarea = styled(TextareaAutosize)`
  font-family: ${({ theme }) => theme.fontName};
  background-color: ${({ theme }) => theme.yin};
  padding: ${spacing(0.875)} ${spacing()};
  border: 2px solid ${({ theme }) => theme.separator};
  border-radius: 6px;
  color: ${({ theme }) => theme.text};
  font-size: 1.25em;
  font-weight: 400;
  outline: none;
  width: 100%;
  resize: none;

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.separatorDark};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: rgba(0, 40, 100, 0.04);
    cursor: not-allowed;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const FieldTypeTriggerButton = styled(ButtonReset)`
  position: absolute;
  color: ${({ theme }) => theme.textLighter};
  font-size: 1.25em;
  right: ${spacing(0.75)};
  top: 50%;
  transform: translateY(-50%);
  display: flex;
`;

export const Fieldset = styled.fieldset`
  border: 0;
  padding-left: 0;
  padding-right: 0;
  margin: ${spacing(2)} 0;
  padding-top: ${spacing()};
  padding-bottom: ${spacing()};
`;

export const FieldsetLegend = styled.legend`
  padding: 0;
  font-size: 1.25em;
`;

export const InputSearch = styled(Input)`
  padding: ${spacing(0.5)} ${spacing(2)} ${spacing(0.5)} ${spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadius};

  ${props =>
    props.variant === 'full' &&
    css`
      padding-left: ${spacing(0.5)};
      width: 100%;
    `};
`;

export const Error = styled(RawErrorMessage)`
  color: #c55;
`;

export const Segmented = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  display: inline-block;
  display: flex;
`;

export const Item = styled.button`
  padding: ${spacing(0.5)} ${spacing()};
  border: 0;
  border-right: 1px solid ${({ theme }) => theme.secondary};
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  color: ${({ theme }) => theme.secondary};
  outline: none;
  flex: 1;
  min-height: ${spacing(2)};
  cursor: pointer;
  background-color: ${({ theme }) => theme.yin};
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.secondary};
  border-width: 1px 0 1px 1px;
  white-space: nowrap;

  &:first-child {
    border-radius: 6px 0 0 6px;
  }

  &:last-child {
    border-right-width: 1px;
    border-radius: 0 6px 6px 0;
  }

  ${props =>
    props.onlyDisplaysIcons &&
    css`
      padding: ${spacing(0.25)} ${spacing(0.5)};
    `};

  ${props =>
    props.isActive &&
    css`
      color: ${({ theme }) => theme.yin};
      background-color: ${({ theme }) => theme.secondary};
    `};

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: #f2f2f2;
      color: #ccc;
    `}
`;
