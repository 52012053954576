import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  FormField,
  FormLabel,
  Input,
  Textarea,
  Segmented,
  Item,
  InputIcon,
  Fieldset,
} from 'ui/forms';
import {
  Form as RawForm,
  Field,
  ErrorMessage as RawErrorMessage,
} from 'formik';
import { uniqueId } from 'lodash';
import { spacing, TitleAndAction } from 'ui';
import { theme } from 'ui/theme';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { ButtonReset } from '@tymate/margaret';

const InputOutter = styled.div`
  position: relative;
`;

const VisibililtyButton = styled(ButtonReset)`
  position: absolute;
  right: ${({ theme }) => theme.spacing()};
  font-size: 1.5em;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  color: ${({ theme }) => theme.textLight};

  ${({ variant }) =>
    variant === 'faded' &&
    css`
      color: ${({ theme }) => theme.textLighter};
    `}
`;

export const ErrorMessageWrapper = styled.div`
  padding-top: ${spacing(0.5)};
  color: ${props => props.theme.error};
`;

export const ErrorMessage = props => (
  <RawErrorMessage {...props} component={ErrorMessageWrapper} />
);

const renderTextField = ({
  field,
  label,
  icon,
  form,
  onSwitchType,
  variant,
  disabled,
  id,
  labelProps,
  secondaryLink,
  subtleError,
  ...props
}) => {
  const hasError = Boolean(form.errors[field.name]) && form.touched[field.name];

  return (
    <FormField icon={icon}>
      <TitleAndAction variant="bare">
        {label && (
          <FormLabel
            hasError={!subtleError && hasError}
            htmlFor={id}
            {...labelProps}
          >
            {label}
          </FormLabel>
        )}
        {secondaryLink}
      </TitleAndAction>
      <InputOutter>
        <Input
          type="text"
          {...field}
          {...props}
          id={id}
          icon={icon}
          hasError={!subtleError && hasError}
          disabled={disabled}
          variant={variant}
        />

        {variant === 'password' && !disabled && (
          <VisibililtyButton
            type="button"
            onClick={onSwitchType}
            variant={props.type === 'password' && 'faded'}
          >
            {props.type === 'password' ? <MdVisibility /> : <MdVisibilityOff />}
          </VisibililtyButton>
        )}
      </InputOutter>
      <InputIcon>{icon}</InputIcon>
      <ErrorMessage name={field.name} />
    </FormField>
  );
};

export const TextField = props => {
  const [id] = useState(uniqueId());
  const [type, setType] = useState(props.type);

  return (
    <Field
      {...props}
      id={id}
      type={type}
      component={renderTextField}
      variant={props.type === 'password' ? 'password' : props.variant}
      onSwitchType={() => setType(type === 'password' ? 'text' : 'password')}
    />
  );
};

const renderTextareaField = ({ field, label, form, ...props }) => {
  const hasError = Boolean(form.errors[field.name]) && form.touched[field.name];

  return (
    <FormField>
      <FormLabel hasError={hasError}>{label}</FormLabel>
      <Textarea {...field} {...props} hasError={hasError} />
      <ErrorMessage name={field.name} />
    </FormField>
  );
};

export const TextareaField = props => {
  const [id] = useState(uniqueId());

  return <Field {...props} id={id} component={renderTextareaField} />;
};

const renderSegmentedControl = ({ field, label, options, form, onChange }) => (
  <FormField>
    <FormLabel>{label}</FormLabel>
    <Segmented>
      {options.map(option => (
        <Item
          onClick={() => {
            if (typeof onChange === 'function') {
              onChange(field.name, option.value);
            }
            form.setFieldValue(field.name, option.value);
          }}
          isActive={field.value === option.value}
          type="button"
          disabled={option.disabled}
        >
          {option.name}
        </Item>
      ))}
    </Segmented>
    <ErrorMessage name={field.name} />
  </FormField>
);

export const SegmentedControl = props => (
  <Field {...props} component={renderSegmentedControl} />
);

export const Form = styled(RawForm)`
  width: 45em;
  max-width: 100%;

  ${props =>
    props.variant === 'full' &&
    css`
      width: 100%;
    `}

  ${props =>
    props.variant === 'centered' &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
`;

const renderCheckbox = ({
  label,
  name,
  value,
  checked,
  handleChange,
  field,
  variant,
  labelStyles,
  ...props
}) => (
  <FormField>
    <FormLabel
      style={{
        ...labelStyles,
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <input
        type="checkbox"
        name={name}
        {...field}
        {...props}
        style={{ display: 'none' }}
      />
      {field.value === true ? (
        <MdCheckBox
          color={variant === 'secondary' ? theme.purple : theme.primary}
        />
      ) : (
        <MdCheckBoxOutlineBlank
          color={variant === 'secondary' ? theme.purple : theme.primary}
        />
      )}
      {Boolean(label) && (
        <span style={{ fontWeight: 400, marginLeft: 10 }}>{label}</span>
      )}
    </FormLabel>
  </FormField>
);

export const CheckboxField = props => (
  <Field {...props} component={renderCheckbox} />
);

const renderRadio = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => (
  <FormLabel htmlFor={id}>
    <input
      name={name}
      id={id}
      type="radio"
      value={id}
      checked={Number(id) === Number(value)}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
      style={{ display: 'none' }}
    />
    {Number(id) === Number(value) ? (
      <MdRadioButtonChecked color={theme.primary} />
    ) : (
      <MdRadioButtonUnchecked color={theme.primary} />
    )}{' '}
    {label}
  </FormLabel>
);

export const RadioButtonField = props => (
  <Field {...props} component={renderRadio} />
);

export const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  children,
}) => (
  <Fieldset>
    {Boolean(label) && <legend>{label}</legend>}
    {children}
  </Fieldset>
);

export const FormValues = ({ values, onChangeValues, isOnline }) => {
  useEffect(() => {
    onChangeValues(values);
    // eslint-disable-next-line
  }, [JSON.stringify(values), isOnline]);

  return null;
};
