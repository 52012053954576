import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { keys, round } from 'lodash';
import { MdChevronRight } from 'react-icons/md';
import { ButtonReset } from '@tymate/margaret';

export const BASE = 1;
export const spacing = (input = 1) => `${input}rem`;

export const size = (input = 0) =>
  `${round(Math.sqrt(Math.sqrt(2)) ** input, 3)}rem`;

export const VIEWPORT_SIZES = {
  largeDesktop: 1300,
  desktop: 1200,
  medium: 1000,
  tablet: 750,
};

const mediaQuery =
  (...query) =>
  (...rules) =>
    css`
      @media ${css(...query)} {
        ${css(...rules)};
      }
    `;

export const media = {
  tablet: mediaQuery`(min-width: ${VIEWPORT_SIZES.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${VIEWPORT_SIZES.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${VIEWPORT_SIZES.desktop / 16}em)`,
  largeDesktop: mediaQuery`(min-width: ${VIEWPORT_SIZES.desktop / 16}em)`,
};

export const fontSize = {
  h1: css`
    font-size: 1.875rem;
    line-height: 2.3125rem;
    letter-spacing: 0.012rem;

    ${media.tablet`
      font-size: 2.375rem;
      line-height: 2.875rem;
      letter-spacing: 0.011em;
    `}

    ${media.desktop`
      font-size: 3rem;
      line-height: 3.5rem;
      letter-spacing: .008em;
    `}
  `,

  h1Mega: css`
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.011em;

    ${media.tablet`
      font-size: 3rem;
      line-height: 3.5625rem;
      letter-spacing: 0.008em;
    `}

    ${media.desktop`
      font-size: 3.75rem;
      line-height: 4.375rem;
      letter-spacing: .004em;
    `}
  `,

  h2: css`
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: 0.015em;

    ${media.tablet`
      font-size: 1.75rem;
      line-height: 2.125rem;
      letter-spacing: 0.013em;
    `}

    ${media.desktop`
      font-size: 2.125rem;
      line-height: 2.5625rem;
      letter-spacing: .011em;
    `}
  `,

  h3: css`
    font-size: 1.375rem;
    line-height: 1.75rem;
    letter-spacing: 0.016em;

    ${media.tablet`
      font-size: 1.5rem;
      line-height: 1.875rem;
      letter-spacing: 0.015em;
    `}

    ${media.desktop`
      font-size: 1.625rem;
      line-height: 2rem;
      letter-spacing: .014em;
    `}
  `,

  body: css`
    font-size: 1.0625rem;
    line-height: 1.375rem;
    letter-spacing: -0.024em;

    ${media.tablet`
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: -.02em;
    `}

    ${media.desktop`
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: -.02em;
    `}
  `,

  bodySmall: css`
    font-size: 0.9375rem;
    line-height: 1.25rem;
    letter-spacing: -0.15em;

    ${media.desktop`
      font-size: 1rem;
      line-height: 1.3125rem;
      letter-spacing: -.02em;
    `}
  `,
};

export const List = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
`;

export const Container = styled.div`
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};
  max-width: 100%;
  width: 75rem;
  margin-left: auto;
  margin-right: auto;

  ${media.tablet`
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  `}

  ${props =>
    props.size === 'medium' &&
    css`
      width: 55rem;
    `}

  ${props =>
    props.size === 'narrow' &&
    css`
      width: 45rem;
    `}

  ${props =>
    props.variant === 'bare' &&
    css`
      padding-left: 0;
      padding-right: 0;

      ${media.tablet`
        padding-left: 0;
        padding-right: 0;
      `}
    `}

  ${props =>
    props.variant === 'main' &&
    css`
      padding-top: ${({ theme }) => theme.spacing(2)};
      padding-bottom: ${({ theme }) => theme.spacing(2)};
    `}

    ${props =>
    props.alignment === 'center' &&
    css`
      display: flex;
      align-items: center;
      flex-direction: column;
    `}
`;

export const GridWrapper = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.yin};
  font-size: 1.25em;
  font-weight: bold;
  color: #000;
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(1.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LinkIconWrapper = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.separator};
  font-size: 1.75em;
`;

export const RawLink = styled(Link)`
  text-decoration: none;
  background-color: ${({ theme }) => theme.yin};
  color: ${({ theme }) => theme.text};
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing(-2)};
  margin-left: ${({ theme }) => theme.spacing(-2)};

  ${props =>
    props.verticalAlign === 'center' &&
    css`
      align-items: center;
    `}

  ${media.tablet`
    width: calc(100% + ${({ theme }) => theme.spacing(2)});
  `}

  ${props =>
    props.gutterVariant === 'medium' &&
    css`
      margin-top: ${({ theme }) => theme.spacing(-1.5)};
      margin-left: ${({ theme }) => theme.spacing(-1.5)};
      width: calc(100% + ${({ theme }) => theme.spacing(1.5)});

      > * {
        padding-top: ${({ theme }) => theme.spacing(1.5)}!important;
        padding-left: ${({ theme }) => theme.spacing(1.5)}!important;
      }
    `};

  ${props =>
    props.gutterVariant === 'large' &&
    css`
      margin-top: ${({ theme }) => theme.spacing(-2)};
      margin-left: ${({ theme }) => theme.spacing(-2)};
      width: calc(100% + ${({ theme }) => theme.spacing(2)});

      > * {
        padding-top: ${({ theme }) => theme.spacing(2)}!important;
        padding-left: ${({ theme }) => theme.spacing(2)}!important;
      }
    `};
`;

export const Cell = styled.div`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  min-width: 0;

  ${props =>
    props.sizes &&
    css`
      flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;

      ${keys(props.sizes)
        .filter(key => key !== 'default')
        .map(
          breakpoint => css`
            ${media[breakpoint]`flex: 0 0 ${
              Math.floor(props.sizes[breakpoint] * 10000) / 100
            }%`};
          `,
        )};
    `};

  ${props =>
    props.min &&
    css`
      flex: 0;
      white-space: nowrap;
    `};

  ${props =>
    props.hiddenUntilTablet &&
    css`
      display: none;

      ${media.tablet`
        display: block;
      `}
    `}
`;

export const Buttons = styled.div`
  margin-left: ${spacing(-1)};
  margin-top: ${spacing(-1)};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    margin-left: ${spacing()};
    margin-top: ${spacing()};
  }

  ${props =>
    props.right &&
    css`
      justify-content: flex-end;
    `};

  ${props =>
    props.left &&
    css`
      justify-content: flex-start;
    `}

  ${props =>
    props.hasTopMargin &&
    css`
      margin-top: ${({ theme }) => theme.spacing()};
    `};

  ${props =>
    props.variant === 'bareTopImportantstyle' &&
    css`
      margintop: '0!important';
    `}

  ${props =>
    props.spacingSize === 'small' &&
    css`
      margin-left: ${spacing(-0.5)};
      margin-top: ${spacing(-0.5)};

      > * {
        margin-left: ${spacing(0.5)};
        margin-top: ${spacing(0.5)};
      }
    `}

  ${props =>
    props.spacingSize === 'big' &&
    css`
      margin-left: ${spacing(-2)};

      > * {
        margin-left: ${spacing(2)};
      }
    `}
`;

export const Title = styled.h1`
  font-size: 38px;
  margin-top: ${spacing()};
  margin-bottom: ${spacing(0.25)};
`;

export const Subtitle = styled.h2`
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.25em;
  font-weight: 400;
`;

export const Heading = styled.h2`
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

export const TextLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  font-weight: 600;
  outline: none;
  border: 0;
  line-height: 1;
  transition: color 150ms ease;

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }

  ${props =>
    props.variant === 'subtle' &&
    css`
      color: ${({ theme }) => theme.textLight};

      &:hover {
        color: ${({ theme }) => theme.text};
      }
    `}
`;

export const MediaImage = styled.div`
  display: flex;
`;

export const MediaBody = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing()};

  ${props =>
    props.variant === 'compact' &&
    css`
      padding-left: ${({ theme }) => theme.spacing(0.5)};
    `};
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;

  ${props =>
    (props.variant === 'center' || props.verticalAlign === 'center') &&
    css`
      align-items: center;
    `};

  ${props =>
    props.isMargedBottom &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    `};

  ${props =>
    props.size === 'tight' &&
    css`
      ${MediaBody} {
        padding-left: ${({ theme }) => theme.spacing(0.5)};
      }
    `};

  ${props =>
    props.variant === 'inverted' &&
    css`
      ${MediaBody} {
        padding-left: 0;
      }
    `}
`;

export const MarkdownContent = styled.div`
  text-align: left;

  img {
    display: block;
    max-width: 100%;
  }
`;

export const Tag = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.yin};
  background-color: ${({ theme }) => theme.primary};
  border-radius: 4px;
  padding: 2px ${({ theme }) => theme.spacing(0.5)};
  z-index: 1;

  ${props =>
    props.variant === 'article' &&
    css`
      vertical-align: middle;
      background-color: ${({ theme }) => theme.secondary};
      border-radius: 99px;
      font-weight: 600;
      padding: ${({ theme }) => theme.spacing(0.25)}
        ${({ theme }) => theme.spacing()};

      svg {
        margin-left: ${spacing(0.5)};
      }
    `}
`;

export const TagList = styled(List)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  display: flex;
  margin-left: ${({ theme }) => theme.spacing(-0.5)};
  flex-wrap: wrap;

  > * {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const GridSidebar = styled.div`
  grid-area: sidebar;
  position: relative;

  ${Buttons} {
    max-width: 260px;
  }
`;

export const GridMain = styled.div`
  grid-area: main;
  display: grid;

  ${props =>
    props.variant === 'withArticles' &&
    css`
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 32px;
      grid-row-gap: 56px;
      ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
        `}
    `}

  ${props =>
    props.variant === 'messages' &&
    css`
      padding-top: ${({ theme }) => theme.spacing()};
      grid-template-rows: 1fr auto;

      ${media.desktop`
        padding-top: 0;
      `}
    `}
`;

export const GridItems = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 56px;

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const GridLayout = styled.div`
  display: block;
  grid-template-columns: 1fr 260px;
  grid-column-gap: 132px;
  grid-template-rows: auto;
  grid-template-areas: 'main sidebar';

  ${media.desktop`
    display: grid;
  `};

  ${props =>
    props.verticalSize === 'auto' &&
    css`
      min-height: 0;

      ${media.desktop`
        min-height: 0;
      `};
    `}

  ${props =>
    props.variant === 'reversed' &&
    css`
      grid-template-areas: 'sidebar main';
      grid-template-columns: 380px 1fr;
    `}
`;

export const Section = styled.section`
  background-color: ${({ theme }) => theme.yin};
  padding-bottom: ${({ theme }) => theme.spacing()};
  padding-top: ${({ theme }) => theme.spacing()};

  ${({ background }) =>
    background === 'grey' &&
    css`
      background-color: #f1f1f1;
      padding-top: 0;
    `}
`;

export const PictureAvatar = styled(ButtonReset)`
  background-size: cover;
  background-position: center center;
  padding-top: 100%;
  border-radius: 100%;
  background-color: #ccc;
  filter: grayscale(80%);
  ${props =>
    props.backgroundUrl &&
    css`
      background-image: url(${props.backgroundUrl});
    `}
  &:hover {
    filter: none;
  }
  ${props =>
    props.isActive &&
    css`
      box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.primary};
      filter: none;
    `};
`;

export const PictureAvatarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

export const PageTitle = styled.h2`
  ${fontSize.h1}

  margin-top: 0;
`;

export const PageSubtitle = styled.h3`
  ${fontSize.h2}
  margin-top: 0;
`;

export const ListItemWrapper = styled.div`
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.separator};
  background-color: ${({ theme }) => theme.yin};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing()};

  p {
    margin: 0;
  }

  + * {
    margin-top: ${spacing()};
  }

  ${({ variant }) =>
    variant === 'message' &&
    css`
      box-shadow: none;
    `}
`;

export const ListItemName = styled.p`
  font-weight: 600;
`;

export const ListItemLabel = styled.p`
  color: ${({ theme }) => theme.textLight};
`;

export const IconAndName = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${spacing(0.75)};
    font-size: 1.25em;
  }
`;

export const LinkIcon = styled(ButtonReset)`
  color: ${({ theme }) => theme.textLight};
  font-size: 1.625em;
  &: hover {
    color: ${({ theme }) => theme.primary};
  }
  + * {
    margin-left: ${({ theme }) => theme.spacing()};
  }
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.separator};
  font-size: 1.75em;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Time = styled.p`
  color: ${({ theme }) => theme.textLight};
  margin: 0;
`;

export const TitleAndAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${PageTitle} {
    margin-bottom: 0;
  }

  ${props =>
    props.variant === 'bare' &&
    css`
      margin-bottom: 0;
    `}
`;

export const CardLinksList = styled(List)``;

export const CardLinkItem = styled.li`
  + li {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const CardLinkWrapper = styled(Link)`
  display: flex;
  padding: ${({ theme }) => theme.spacing()};
  background-color: ${({ theme }) => theme.yin};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: inherit;
  text-decoration: none;
  justify-content: space-between;
  box-shadow: 0 2px 4px ${({ theme }) => theme.boxShadowColor};
  &:hover {
    box-shadow: 0 4px 8px ${({ theme }) => theme.boxShadowColorHover};
  }

  ${props =>
    props.variant === 'raised' &&
    css`
      border-radius: 8px;
      // box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.separator};
      padding: ${spacing(1.5)};
      border: 0;
    `}
`;

const CardLinkLabel = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.textLight};
  display: flex;
  align-items: center;
`;

const CardLinkContent = styled.div`
  flex: 1;
`;

export const CardLink = ({ to, children, label, variant }) => (
  <CardLinkWrapper variant={variant} to={to}>
    <CardLinkContent>{children}</CardLinkContent>

    <CardLinkLabel>
      {label} <MdChevronRight size="22" />
    </CardLinkLabel>
  </CardLinkWrapper>
);

export const ResetLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
`;

export const Sticky = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: block;
  max-width: 320px;

  ${({ variant }) =>
    variant === 'bareTop' &&
    css`
      margin-top: 0;
    `}

  ${media.desktop`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 0;
    position: sticky;
    top: 40px;
  `}

  ${({ size }) =>
    size === 'full' &&
    css`
      max-width: none;
      width: 100%;
    `}
`;

export const WrapperTagInput = styled.div`
  position: relative;
`;

export const ButtonTagInput = styled(ButtonReset)`
  position: absolute;
  border-radius: 100%;
  color: ${({ theme }) => theme.primary};
  top: 50%;
  right: ${({ theme }) => theme.spacing(0.5)};
  transform: translateY(-50%);

  ${props =>
    props.disabled &&
    css`
      color: ${({ theme }) => theme.separator};
    `}

  ${({ variant }) =>
    variant === 'static' &&
    `
      position: static;
      transform: none; 
    `}
`;

export const UnreadBadge = styled.span`
  font-size: 15px;
  color: #fff;
  background-color: ${({ theme }) => theme.primary};
  display: inline-block;
  height: 22px;
  min-width: 22px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 7px;
  margin-right: ${({ theme }) => theme.spacing(0.5)};

  ${props =>
    props.variant === 'header' &&
    css`
      margin-left: ${({ theme }) => theme.spacing(0.25)};
      display: flex;
      align-items: center;
      transform: translateY(-6px);
    `}
`;

export const WidgetButton = styled(ButtonReset)`
  border: 1px solid ${({ theme }) => theme.separator};
  background-color: ${({ theme }) => theme.yin};
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.24);
  border-radius: 100%;
  width: 45px;
  height: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #c7c7c7;
  font-size: 16px;

  svg {
    color: #c7c7c7;
  }

  ${({ fontFamily }) =>
    Boolean(fontFamily) &&
    css`
      font-family: ${fontFamily}, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    `}

  ${props =>
    props.isActive &&
    css`
      border: 0;
      background-color: #89c1e9;
      box-shadow: none;
      color: ${({ theme }) => theme.yin};
      svg {
        color: ${({ theme }) => theme.yin};
      }
    `}

  &:hover {
    border: 0;
    background-color: #89c1e9;
    box-shadow: none;
    color: ${({ theme }) => theme.yin};
    svg {
      color: ${({ theme }) => theme.yin};
    }
  }
`;

export const CategoryInput = styled.input`
  ${fontSize.body}

  border:0;
  border-radius: 8px;
  flex: 1;
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(0.25)} 0;
`;

export const CategoryItem = styled.li`
  border: 1px solid ${({ theme }) => theme.boxShadowColor};
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};

  + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

export const CategoryButtons = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  button {
    margin-left: ${({ theme }) => theme.spacing()};
  }

  svg {
    color: ${({ theme }) => theme.textLight};
    font-size: 1.5em;
    ${props =>
      props.isActive &&
      css`
        color: ${({ theme }) => theme.primary};
      `}
  }
`;

export const AttachmentWrapper = styled.div`
  max-width: 15em;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  > img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

export const AttachmentsGrid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 16px;
`;

export const AttachmentDeleteButton = styled(ButtonReset)`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.spacing(0.5)};
  right: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.text};
  border-radius: 100%;
  background-color: #fff;
  display: flex;
`;

export const Image = styled.img`
  height: 336px;
  width: 336px;
  border-radius: 8px;
  object-fit: cover;
`;

export const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: ${({ theme }) => theme.spacing(1)};
  grid-row-gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};

  ${media.tablet`
    grid-template-columns: repeat(3, 1fr);
  `}
`;
