import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import { isEmpty, omit, trim, truncate, pickBy } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { startsWith } from 'lodash';
import { getEditorStateFromHtml, getHTMLFromEditorState } from './editor';
import heic2any from 'heic2any';

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  TAKEN: 'Cette valeur est déjà prise',
  INVALID: 'Cette valeur n’est pas valide',
  EMAIL: 'Cette adresse mail n’est pas valide',
  PASSWORDS_DO_NOT_MATCH: 'Les mots de passe ne concordent pas',
  ONE_GOOD_ANSWER_MANDATORY: 'Aucune bonne réponse n’est sélectionnée',
  AT_LEAST_TWO_ANSWERS_MANDATORY:
    'Une question doit au moins posséder deux propositions',
};

export const formatDate = (date, frmt) => {
  if (!Boolean(date)) {
    return '';
  }

  if (!Boolean(frmt)) {
    try {
      return new Date(date).toISOString();
    } catch (err) {
      return '';
    }
  }

  try {
    return format(new Date(date), frmt, { locale: fr });
  } catch (err) {
    try {
      return format(date, frmt, { locale: fr });
    } catch (err) {
      return '';
    }
  }
};

export const pluralize = (input, count) =>
  input
    .split(' ')
    .map(i => `${i}${count > 1 ? 's' : ''}`)
    .join(' ');

export const getUserDisplayName = ({
  firstName,
  lastName,
  email,
  truncateLength,
}) => {
  if (!firstName && !lastName) {
    return Boolean(truncateLength)
      ? truncate(email, { length: truncateLength, omission: '…' })
      : email;
  }

  const fullName = trim(`${firstName} ${lastName}`);

  return Boolean(truncateLength)
    ? truncate(fullName, { length: truncateLength, omission: '…' })
    : fullName;
};

const hasArrayBufferView = () =>
  new Blob([new window.Uint8Array(100)]).size === 100;

export const dataUriToBlob = uri => {
  const data = uri.split(',')[1];
  const bytes = atob(data);
  const buffer = new window.ArrayBuffer(bytes.length);
  let array = new window.Uint8Array(buffer);

  for (let i = 0; i < bytes.length; i++) {
    array[i] = bytes.charCodeAt(i);
  }

  if (!hasArrayBufferView()) {
    array = buffer;
  }

  const type = uri.split(',')[0].split(':')[1].split(';')[0];

  const blob = new Blob([array], { type });

  blob.slice = blob.slice || blob.webkitSlice;

  return blob;
};

export const imageToDataUri = async url => {
  const canvas = window.document.createElement('canvas');
  let img = new Image();
  img.src = url;
  img.setAttribute('crossOrigin', 'anonymous');

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const dataUri = canvas.toDataURL('image/jpeg');
      resolve(dataUri);
    };

    img.onerror = () => reject('Erreur');
  });
};

export const sanitizeHTML = (html = '') => {
  const modifiedHtml = html?.replace(
    /<img(.+?)alt="(.+?)"(.+?)\/{0,1}>/g,
    '<figure><img$1alt="$2"$3/><figcaption>$2</figcaption></figure>',
  );

  return sanitizeHtml(modifiedHtml, {
    transformTags: {
      h1: 'h2',
      h2: 'h3',
      ins: 'u',
    },
    allowedClasses: {
      span: ['spoil'],
    },
    allowedTags: [
      'h2',
      'h3',
      'p',
      'blockquote',
      'a',
      'ul',
      'ol',
      'li',
      'strong',
      'em',
      'u',
      's',
      'img',
      'br',
      'span',
      'pre',
      'figure',
      'figcaption',
      'del',
    ],
    allowedAttributes: {
      a: ['href', 'target', 'rel'],
      img: ['alt', 'src', 'align'],
    },
  });
};

export const versionsNames = {
  original: 'Version originale',
  reviewed: 'Version relue',
  long: 'Version longue',
  short: 'Version courte',
  other: 'Autre',
};

export const getVersionValue = label => {
  switch (label) {
    case 'Version originale':
      return 'original';
    case 'Version relue':
      return 'reviewed';
    case 'Version longue':
      return 'long';
    case 'Version courte':
      return 'short';
    case 'Autre':
      return 'other';
    default:
      return label;
  }
};

export const getVisitDay = weekDay => {
  const day = d => {
    switch (d) {
      case 'monday':
        return 'Lundi';
      case 'tuesday':
        return 'Mardi';
      case 'wednesday':
        return 'Mercredi';
      case 'thursday':
        return 'Jeudi';
      case 'friday':
        return 'Vendredi';
      case 'saturday':
        return 'Samedi';
      case 'sunday':
        return 'Dimanche';
      default:
        return '';
    }
  };

  const moment = m => {
    switch (m) {
      case 'afternoon':
        return 'après-midi';
      case 'morning':
        return 'matin';
      default:
        return '';
    }
  };

  return `${day(weekDay.split('_')[0])} ${moment(weekDay.split('_')[1])}`;
};

export const maxFileSize = 75 * 1000000; // 75Mo
export const FILE_ERROR =
  'Le fichier est trop volumineux. Veuillez choisir un fichier de moins de 75Mo';

export const convertHEICtoJPEG = async file => {
  let jpegFile = file;

  if (file?.type === 'image/heic' || file?.type === 'image/heif') {
    const convertedFiles = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.7,
    });
    jpegFile = new File([convertedFiles], file?.name, {
      type: 'image/jpeg',
    });
  }

  return jpegFile;
};

export const draftIsEmpty = draft => {
  if (isEmpty(draft)) {
    return true;
  }

  const { address, body, categoryIds, country, lat, lng, tags, title } = draft;
  return (
    !address &&
    !categoryIds &&
    !country &&
    !lat &&
    !lng &&
    !title &&
    tags.length === 0 &&
    body.length <= 9
  );
};

export const formatArticleValues = article => ({
  id: article?.id,
  body: getEditorStateFromHtml(article?.body ?? ''),
  title: article?.title ?? '',
  country: article?.country ?? '',
  address: article?.address ?? '',
  lat: article?.lat ?? '',
  lng: article?.lng ?? '',
  tagsAttributes: (article?.tags ?? []).map(({ displayName }) => ({
    displayName,
  })),
  categoryId: article?.categories?.[0]?.id ?? '',
  pictureUrl: article?.pictureUrl ?? article?.picture?.imageUrl ?? '',
  publish: false,
});

export const parseArticleValues = ({ categoryId, ...values }) => {
  const omittedValues = omit(values, ['id', 'title']);

  return {
    ...pickBy({
      ...omittedValues,
      body: getHTMLFromEditorState(values.body),
      categoryIds: [categoryId].filter(Boolean),
      stateEvent: values.publish ? 'to_validate' : null,
    }),
    title: values.title || '',
    pictureUrl: values.pictureUrl || '',
  };
};

export const getIsDraft = ({ articleId }) => startsWith(articleId, 'nouveau-');

export const isAndroid = /Android/i.test(navigator.userAgent);
