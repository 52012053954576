import { useState } from 'react';
import styled from 'styled-components';
import AuthLayout from 'components/AuthLayout';
import { Form, TextField, ErrorMessageWrapper } from 'components/Forms';
import { Formik } from 'formik';
import { postForgotPassword } from 'api/auth';
import { Buttons, TextLink } from 'ui';
import * as Yup from 'yup';
import { ERRORS } from 'utils';
import icChecked from 'images/icons/checked.svg';
import { useAuth } from 'hooks';
import { Navigate } from 'react-router-dom';
import { Button } from 'components';

const WrapperStepTwo = styled.div`
display: flex;
flex-direction: column;
margin-bottom: ${({ theme }) => theme.spacing(2)}
  img {
    height: 60px;
    margin-bottom: ${({ theme }) => theme.spacing()}
`;

const forgottenPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
});

const ForgottenPasswordForm = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={forgottenPasswordFormSchema}
    initialValues={{ email: '' }}
  >
    {({ isSubmitting, status }) => (
      <Form>
        <TextField name="email" label="Adresse mail" />
        <TextLink
          style={{ display: 'inline-block', marginTop: 8, marginBottom: 32 }}
          to="/connexion"
        >
          Je me souviens de mon mot de passe
        </TextLink>

        {Boolean(status) && (
          <ErrorMessageWrapper style={{ marginBottom: 32 }}>
            {status}
          </ErrorMessageWrapper>
        )}

        <Buttons>
          <Button variant="primary" isLoading={isSubmitting}>
            Continuer
          </Button>
        </Buttons>
      </Form>
    )}
  </Formik>
);

const ForgottenPassword = () => {
  const { accessToken } = useAuth();
  const [step, setStep] = useState(1);

  const handleSubmitForgottenPasswordForm = async (
    values,
    { setStatus, setSubmitting },
  ) => {
    try {
      await postForgotPassword(values);
      setSubmitting(false);
      setStep(2);
    } catch (err) {
      setStatus(
        'L’adresse mail entrée n’existe pas dans notre base de données',
      );
      setSubmitting(false);
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout
      title="Mot de passe oublié"
      shouldHideHeader={step === 2}
      shouldHideFooter={step === 2}
    >
      {step === 1 && (
        <ForgottenPasswordForm onSubmit={handleSubmitForgottenPasswordForm} />
      )}
      {step === 2 && (
        <div>
          <WrapperStepTwo>
            <img src={icChecked} alt="" />
            Vous avez reçu un email !
          </WrapperStepTwo>
          <TextLink
            to="/connexion"
            style={{ display: 'inline-block', marginTop: 8 }}
          >
            Retour à la connexion
          </TextLink>
        </div>
      )}
    </AuthLayout>
  );
};

export default ForgottenPassword;
