import { Formik } from 'formik';
import { Form, TextField, TextareaField, ErrorMessageWrapper } from '.';
import { Buttons } from 'ui';
import Modal from '../Modal';
import { sendContactMail } from 'api/general';
import { getTravelerTravel } from 'api/travel';
import { useAuth } from 'hooks';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { ERRORS } from 'utils';
import { Button } from 'components';

const ContactForm = ({ isOpen, onRequestClose, tag }) => {
  const { user } = useAuth();

  const { data: travelData } = useQuery(
    ['travelerTravel', user?.role],
    getTravelerTravel,
    { enabled: user?.role === 'traveler' },
  );

  const handleSubmit = async (values, { setStatus }) => {
    const parsedValues = {
      subject: `[${tag}] ${values.subject}`,
      body: `${
        travelData && user.role === 'traveler'
          ? `Voyage: ${travelData?.data?.name} <br />`
          : ''
      }
      ${values.body}
      `,
    };
    try {
      await sendContactMail(parsedValues);
      onRequestClose();
    } catch (err) {
      setStatus('Une erreur est survenue, veuillez réessayer plus tard.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="Contactez-nous"
    >
      <Formik
        initialValues={{ subject: '', body: '' }}
        validationSchema={Yup.object().shape({
          subject: Yup.string().required(ERRORS.REQUIRED),
          body: Yup.string().required(ERRORS.REQUIRED),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <TextField label="Sujet" type="text" name="subject" />
            <TextareaField minRows={4} label="Message" name="body" />

            {status && <ErrorMessageWrapper>{status}</ErrorMessageWrapper>}

            <Buttons hasTopMargin>
              <Button
                type="button"
                onClick={onRequestClose}
                variant="secondary"
              >
                Annuler
              </Button>
              <Button type="submit" variant="primary" isLoading={isSubmitting}>
                Envoyer
              </Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ContactForm;
