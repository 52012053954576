import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  ::-moz-selection { /* Code for Firefox */
  background: ${({ theme, isReadHelper }) =>
    isReadHelper ? theme.readHelperSelectionColor : theme.selectionColor};
    color: ${({ theme, isReadHelper }) =>
      isReadHelper ? 'inherit' : theme.selectedTextColor};
  }

  ::selection {
    background: ${({ theme, isReadHelper }) =>
      isReadHelper ? theme.readHelperSelectionColor : theme.selectionColor};
    color: ${({ theme, isReadHelper }) =>
      isReadHelper ? 'inherit' : theme.selectedTextColor};
  }

  html {
    scroll-behavior: smooth;
  }

  em-emoji-picker {
    position: absolute;
    z-index: 1000;
    right:10px;    
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.text};
    line-height: 1.4;
    font-family: ${({ theme }) => theme.fontName},
      -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

  code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  img {
    max-width: 100%;
  }

  figure {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: ${({ theme }) => theme.spacing()};
    margin-bottom: ${({ theme }) => theme.spacing()};
  }

  .medium-zoom-image--opened {
    z-index: 6765335900
  }

  .medium-zoom-overlay {
    z-index: 6765335898;
  }

  .react-viewer-canvas,
  .react-viewer-footer {
    z-index: 2147483146!important;

    .react-viewer-attribute {
    font-size: 24px;
    margin-bottom: 32px;
    }
  }

  

  .react-viewer-mask.react-viewer-mask {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .react-viewer-toolbar.react-viewer-toolbar {
    height: 45px;
    display: flex;
    justify-content: center;
    
    li {
      display: inline-block;
      width: 45px;
      height: 45px;
      margin-right: ${({ theme }) => theme.spacing(0.5)};
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.separator};
      background-color: ${({ theme }) => theme.backgroundDark};
      box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.24);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #c7c7c7;

      &:hover {
        background-color: #89c1e9;
        .react-viewer-icon {
          color: #ffffff;
        }
      }
    }

    .react-viewer-icon {
      color: #c7c7c7;
      font-size: 16px;
    }
  }
`;

export default GlobalStyles;
