import styled from 'styled-components';
import { Page } from 'ui/layout';
import { media } from 'ui';
import background from 'images/hero-connection.png';
import { Card, CardContent, CardTitle, CardSubtitle } from 'ui/cards';
import logo from 'images/logo.svg';

const Background = styled.div`
  ${media.tablet`
    flex: 1;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.primary};
    background-image: url(${background});
    background-size: cover;
    background-position: bottom center;
  `}
`;

const Content = styled.div`
  display: flex;
  justify-content: center;

  ${media.tablet`
    padding-top: ${({ theme }) => theme.spacing(6)};
    padding-bottom: ${({ theme }) => theme.spacing(6)};
  `}
`;

const WrapperRedirection = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    color: #000;
    font-weight: bold;
  }
`;

const AuthLayout = ({
  children,
  title,
  shouldHideHeader,
  shouldHideFooter,
}) => (
  <Page>
    <Background>
      <Content>
        <Card size="fixed" style={{ border: 0 }}>
          <CardContent variant="padded" alignment="center">
            {!shouldHideHeader && (
              <>
                <img src={logo} alt="kolibri" />
                <CardTitle style={{ textAlign: 'center' }}>{title}</CardTitle>
                <CardSubtitle variant="auth">
                  une web-application développée <br />
                  par l’association L'enfant@l'hôpital !
                </CardSubtitle>
              </>
            )}
            {children}
            {!shouldHideFooter && (
              <>
                <WrapperRedirection>
                  <p>
                    Ce site ne peut être utilisé que dans le cadre d'un
                    partenariat avec L’enfant@l’hôpital, encadré par une
                    convention. Pour vous y connecter, vous devez posséder un
                    compte délivré par l’association.
                  </p>
                  <p>
                    Visiter le site{' '}
                    <a
                      href="http://www.enfant-hopital.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.enfant-hopital.org
                    </a>
                  </p>
                </WrapperRedirection>
              </>
            )}
          </CardContent>
        </Card>
      </Content>
    </Background>
  </Page>
);

export default AuthLayout;
