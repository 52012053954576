import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DataContext } from 'contexts';
import { getCategories } from 'api/general';
import { useAuth } from 'hooks';
import ContactForm from 'components/Forms/ContactForm';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import { useQuery } from 'react-query';
import * as Fathom from 'fathom-client';
import { FATHOM_ID } from '../constants';

const initialRoutesNames = {
  '/a-propos': 'À propos',
  '/arts-et-cultures': 'Arts et cultures',
  '/base-de-connaissances': 'Bases de connaissances',
  '/bibliotheque': 'Bibliothèque',
  '/geographie': 'Géographie',
  '/jeux-et-activites': 'Jeux et activités',
  '/lire-creer-ecouter': 'Lire, créer, écouter',
  '/mediatheque': 'Médiathèque',
  '/mes-groupes': 'Mes groupes',
  '/metiers': 'Métiers',
  '/mon-profil': 'Mon profil',
  '/mes-articles': 'Mes articles',
  '/quiz': 'Quiz',
  '/sciences-et-decouvertes': 'Sciences et découvertes',
  '/etablissements': 'Établissements',
  '/mes-groupes/etablissements': 'Par établissement',
  '/mes-groupes/calendrier': 'Par calendrier',
  '/nouveau': 'Nouveau',
  '/galerie-photos': 'Galerie photos',
};

const DataProvider = ({ children }) => {
  const location = useLocation();
  const [routesNames, setRoutesNames] = useState(initialRoutesNames);
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactTag, setContactTag] = useState('');
  const { user } = useAuth();
  const { pathname } = location;

  const { data: knowledgeCategoriesData } = useQuery(
    ['knowledgeCategories'],
    getCategories,
    { enabled: Boolean(user?.id) },
  );

  const knowledgeCategories = knowledgeCategoriesData?.data || [];

  const setRouteName = (name, path) => {
    setRoutesNames({
      ...routesNames,
      [path || location.pathname]: name,
    });
  };

  const pathsRegexesWithPlaceholder = [
    /voyages(\/[^/]+){1}$/,
    /mes-groupes(\/[^/]+){2}$/,
    /(jeux-et-activites|bibliotheque)(\/[^/]+){1}\/[^/]+$/,
  ];

  const pathsRegexesWithoutCrumb = [
    /(jeux-et-activites|bibliotheque)(\/[^/]+?){1}$/,
    /voyages\/\d+?\/(articles|mediatheque|a-propos|carte)$/,
    /voyages\/\d+?\/articles\/\d+?\/.+/,
  ];

  const pathsRegexesWithHiddenBreadcrumb = [
    /^\/?[^/]+$/,
    /(jeux-et-activites|bibliotheque)(\/[^/]+?){1}$/,
  ];

  const handleOpenContactModal = tag => {
    setShowContactModal(true);
    setContactTag(tag);
  };

  const setMultiRoutesNames = newRoutesNames => {
    setRoutesNames({ ...routesNames, ...newRoutesNames });
  };

  useDeepCompareEffect(() => {
    const routesNames = knowledgeCategories.map(
      ({ kind, id, displayName }) => ({
        name: displayName,
        path: `/${
          kind === 'library' ? 'bibliotheque' : 'jeux-et-activites'
        }/${id}`,
      }),
    );
    setMultiRoutesNames(routesNames);
  }, [{ knowledgeCategories }]);

  useEffectOnce(() => {
    Fathom?.load(FATHOM_ID);
  });

  useDeepCompareEffect(() => {
    Fathom?.trackPageview();
  }, [{ pathname }]);

  const libraryCategories = knowledgeCategories.filter(
    ({ kind }) => kind === 'library',
  );

  const activityCategories = knowledgeCategories.filter(
    ({ kind }) => kind === 'activity',
  );

  return (
    <DataContext.Provider
      value={{
        routesNames,
        setRouteName,
        setRoutesNames,
        setMultiRoutesNames,
        libraryCategories,
        activityCategories,
        handleOpenContactModal,
        pathsRegexesWithoutCrumb,
        pathsRegexesWithPlaceholder,
        pathsRegexesWithHiddenBreadcrumb,
      }}
    >
      {children}
      {showContactModal && (
        <ContactForm
          isOpen
          onRequestClose={() => {
            setShowContactModal(false);
            setContactTag(null);
          }}
          tag={contactTag}
        />
      )}
    </DataContext.Provider>
  );
};

export default DataProvider;
