import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import { fontSize } from 'ui';
import { ButtonReset } from '@tymate/margaret';
import { MdClose } from 'react-icons/md';
import { pickBy } from 'lodash';

const StyledModal = styled(ReactModal)`
  position: absolute;
  background-color: ${({ theme }) => theme.yin};
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 2147483646;
  padding: 0;
  max-height: calc(100vh - 32px);
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  outline: none;
  border: 0;
  width: 700px;
  max-width: calc(100vw - 32px);

  ${props =>
    props.variant === 'full' &&
    css`
      width: auto;
    `}

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}

    ${({ memoryModal }) =>
    memoryModal &&
    css`
      height: 98%;
    `}

  ${props =>
    props.variant === 'medium' &&
    css`
      width: 900px;
    `}

  ${props =>
    props.variant === 'big' &&
    css`
      width: 1400px;
    `}

  ${props =>
    props.variant === 'fullscreen' &&
    css`
      width: 100%;
      margin-right: 0;
      top: 0;
      left: 0;
      max-width: 100%;
      transform: none;
      max-height: 100%;
      height: 100%;
      padding: 0;
    `}
`;

ReactModal.setAppElement(document.getElementById('root'));

export const CloseModalTriggerButton = styled(ButtonReset)`
  font-size: 1.5em;
  position: absolute;
  z-index: 2;
  top: ${({ theme }) => theme.spacing()};
  right: ${({ theme }) => theme.spacing()};
  color: #c3b8c6;

  ${props =>
    props.variant === 'fullscreen' &&
    css`
      position: fixed;
      border-radius: 100%;
      min-height: 32px;
      min-width: 32px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.separator};
    `};
`;

const ModalTitle = styled.h1`
  ${fontSize.h3}

  margin: 0;
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(1.5)};

  ${props =>
    props.variant === 'fullscreen' &&
    css`
      height: 100%;
      max-height: 100%;
    `}

  ${props =>
    (props.variant === 'full' || props.variant === 'fullscreen') &&
    css`
      padding: 0;
    `}
`;

const Modal = ({
  title,
  children,
  isOpen,
  onRequestClose,
  overflow,
  variant,
  background,
  shouldCloseOnOverlayClick,
  minHeight,
  memoryModal,
}) => {
  const overlayStyles = {
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  };

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'auto';
    }

    return () => {
      document.documentElement.style.overflowY = 'auto';
    };
  }, [isOpen]);

  return (
    <StyledModal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      background={background}
      variant={variant}
      overflow={overflow}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      minHeight={minHeight}
      memoryModal={memoryModal}
      style={{
        overlay: {
          ...overlayStyles,
          ...pickBy(
            background === 'dark' && { backgroundColor: 'rgb(0, 0, 0)' },
          ),
        },
        content: { overflow },
      }}
    >
      <Content variant={variant}>
        {title && <ModalTitle>{title}</ModalTitle>}

        {onRequestClose && (
          <CloseModalTriggerButton onClick={onRequestClose} variant={variant}>
            <MdClose />
          </CloseModalTriggerButton>
        )}

        {children}
      </Content>
    </StyledModal>
  );
};

export default Modal;
