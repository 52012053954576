import styled, { css } from 'styled-components';
import { Button as MgtButton } from '@tymate/margaret';
import { Link } from 'react-router-dom';
import Spinner from 'components/Spinner';

const ButtonBase = ({
  isLoading,
  children,
  icon,
  as,
  rightIcon,
  to,
  disabled,
  ...props
}) => (
  <MgtButton
    {...props}
    to={!disabled && to}
    as={!disabled && to ? Link : as ? as : null}
    disabled={disabled || isLoading}
  >
    {isLoading && <Spinner style={{ marginRight: 16 }} variant="button" />}
    {!isLoading && icon}
    {children}
    {rightIcon}
  </MgtButton>
);

const Button = styled(ButtonBase)`
  min-height: 52px;

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      &:disabled {
        cursor: not-allowed;
        &:hover {
          background-color: ${({ theme }) => theme.boxShadowColor};
        }
      }
    `}
  ${({ variant }) =>
    variant === 'outline' &&
    css`
      color: ${({ theme }) => theme.textLight};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.textLight};
      svg {
        font-size: 1.5em;
      }

      &:disabled {
        cursor: not-allowed;
        &:hover {
          background-color: ${({ theme }) => theme.boxShadowColor};
        }
      }
      text-decoration: none;
    `}

    ${({ variant }) =>
    variant === 'square' &&
    css`
      svg {
        font-size: 1.5em;
      }
    `}

    ${({ variant }) =>
    variant === 'icon' &&
    css`
      display: grid;
      place-content: center;
      min-width: min-content;
      padding: 1rem;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.textLight};
      border-radius: 100px;
      color: ${({ theme }) => theme.textLight};

      svg {
        margin: 0;
      }
    `}
    
    ${props =>
    props.size === 'big' &&
    css`
      font-size: 1.5em;
    `}

    ${props =>
    props.variant === 'textButton' &&
    css`
      color: ${({ theme }) => theme.text};
      padding: 0;
      min-height: 0;
      font-weight: 600;

      &:hover {
        color: ${({ theme }) => theme.primary};
        background-color: transparent;
      }
    `}
`;

export default Button;
