import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AuthLayout from 'components/AuthLayout';
import { signIn } from 'api/auth';
import { useAuth } from 'hooks';
import LoginForm from 'components/Forms/LoginForm';

const Auth = () => {
  const { accessToken, onUpdate } = useAuth();
  const [shouldBeRedirected, setShouldBeRedirected] = useState(false);

  useEffect(() => {
    if (Boolean(accessToken)) {
      setShouldBeRedirected(true);
    }
  }, [accessToken]);

  const handleSignIn = async (values, { setStatus }) => {
    try {
      const { data } = await signIn(values);
      onUpdate(data);
    } catch (err) {
      setStatus(err?.response?.data?.error);
    }
  };

  if (shouldBeRedirected) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout title="Bienvenue sur Kolibri">
      <LoginForm onSubmit={handleSignIn} />
    </AuthLayout>
  );
};

export default Auth;
