import { get, destroy, post, put } from './';

export const getTravels = ({ params }) => get(`/api/v1/travels`, { params });

export const getTravel = ({ travelId, params }) =>
  get(`/api/v1/travels/${travelId}`, { params });

export const getTravelerTravel = () => get(`/api/v1/travel`);

export const getTravelerArticle = ({ articleId }) =>
  get(`/api/v1/articles/${articleId}`);

export const getTravelArticle = ({ articleId }) =>
  get(`/api/v1/articles/${articleId}`);

export const updateTravelerTravel = payload => put(`/api/v1/travel`, payload);

export const getTravelerArticles = ({ params }) =>
  get(`/api/v1/travel/articles`, { params });

export const getTravelerArticleChildrenGroups = ({ articleId }) =>
  get(`/api/v1/articles/${articleId}/children_groups`);

export const addTravelerArticle = payload =>
  post(`/api/v1/travel/articles`, payload);

export const updateTravelerArticle = (articleId, payload) =>
  put(`/api/v1/articles/${articleId}`, payload);

export const getArticle = ({ articleId }) =>
  get(`/api/v1/articles/${articleId}`);

export const downloadArticle = ({ articleId }) =>
  get(`/api/v1/articles/${articleId}?pdf=true`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });

export const getArticlePDF = ({ articleId }) =>
  get(`/api/v1/articles/${articleId}?pdf=true`);

export const getTravelMap = ({ travelId }) =>
  get(`/api/v1/travels/${travelId}/articles?view=route`);

export const removeTravelerArticle = articleId =>
  destroy(`/api/v1/articles/${articleId}`);

export const getTravelerCategories = ({ params }) =>
  get(`/api/v1/categories`, { params });

export const removeTravelerCategory = categoryId =>
  destroy(`/api/v1/categories/${categoryId}`);

export const addTravelerCategory = payload =>
  post(`/api/v1/categories`, payload);

export const updateTravelerCategory = (categoryId, payload) =>
  put(`/api/v1/categories/${categoryId}`, payload);

export const getTravelArticles = ({ sliceId, params }) =>
  get(`/api/v1/travels/${sliceId}/articles`, { params });

export const getTravelerArticleAttachments = ({ articleId }) =>
  get(`/api/v1/articles/${articleId}/attachments`);

export const postTravelerArticleAttachments = (articleId, payload) =>
  post(`/api/v1/articles/${articleId}/attachments`, payload);

export const removeTravelerArticleAttachment = attachmentId =>
  destroy(`/api/v1/attachments/${attachmentId}`);

export const getTravelArticleAttachments = ({ articleId, params }) =>
  get(`/api/v1/articles/${articleId}/attachments`, { params });

export const getTravelAttachments = ({ travelId, params }) =>
  get(`/api/v1/travels/${travelId}/attachments`, { params });

export const getGroups = () => get(`/api/v1/institutions`);

export const getTravelArticleMessages = ({ articleId, params }) =>
  get(`/api/v1/articles/${articleId}/messages`, { params });

export const postTravelArticleMessages = (articleId, payload) =>
  post(`/api/v1/articles/${articleId}/messages`, payload);

export const getChildrenGroups = ({ params }) =>
  get(`/api/v1/children_groups`, { params });

export const getCategories = () => get(`/api/v1/categories`);

export const getKnowledgesArticle = ({ articleId }) =>
  get(`/api/v1/articles/${articleId}/knowledges`);

export const getArticleVersions = ({ articleId, params }) =>
  get(`/api/v1/articles/${articleId}/versions`, { params });

export const getVersion = ({ versionId }) =>
  get(`/api/v1/versions/${versionId}`);

export const updateArticleVersion = (versionId, payload) =>
  put(`/api/v1/versions/${versionId}`, payload);

export const postArticleVersion = (articleId, payload) =>
  post(`/api/v1/articles/${articleId}/versions`, payload);

export const removeAttachment = attachmentId =>
  destroy(`/api/v1/attachments/${attachmentId}`);

export const linkArticleToTravelArticle = (articleId, payload) =>
  put(`/api/v1/articles/${articleId}/knowledges`, payload);

export const unlinkArticleToTravelArticle = (articleId, params) =>
  destroy(`/api/v1/articles/${articleId}/knowledges`, { params });

export const getTravelMessages = () => get(`/api/v1/travel/messages`);

export const createAttachment = payload => post(`/api/v1/attachments`, payload);

export const updateAttachment = (attachhmentId, payload) =>
  put(`/api/v1/attachments/${attachhmentId}`, payload);
