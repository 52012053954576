import { signup } from 'api/auth';
import AuthLayout from 'components/AuthLayout';

import queryString from 'querystring';
import { get } from 'lodash';
import { useAuth } from 'hooks';
import SignupForm from 'components/Forms/SignupForm';
import { camelizeKeys } from 'humps';
import { Navigate, useLocation } from 'react-router-dom';

const formatErrors = input =>
  input
    .map(error => camelizeKeys(error))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

const Signup = () => {
  const { search } = useLocation();
  const { onUpdate, accessToken } = useAuth();
  const inviteToken = get(queryString.parse(search), '?token');

  const handleSignup = async (values, { setStatus, setErrors }) => {
    try {
      const { data } = await signup({ ...values, inviteToken });
      const { accessToken, refreshToken } = data;
      onUpdate({ accessToken, refreshToken });
    } catch (err) {
      const errors = err?.response?.data?.error?.message;
      setErrors(formatErrors(errors));
      setStatus(errors);
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout title="Activer mon compte Kolibri">
      <SignupForm onSubmit={handleSignup} />
    </AuthLayout>
  );
};

export default Signup;
