import styled, { css } from 'styled-components';
import { media, fontSize } from './';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.yin};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  max-width: 100vw;
  color: inherit;
  text-decoration: none;
  display: block;

  ${({ size }) =>
    size === 'fixed' &&
    css`
      ${media.tablet`
        width: 550px;
        max-width: calc(100vw - ${({ theme }) => theme.spacing(2)});
      `}
    `}

  ${({ isSpaced }) =>
    isSpaced &&
    css`
      margin-top: ${({ theme }) => theme.spacing(2)};
    `}

    ${({ gridColumnStart }) =>
    gridColumnStart &&
    css`
      ${media.desktop`grid-column-start: ${props => props.gridColumnStart}`};
    `}

    ${({ gridColumnEnd }) =>
    gridColumnEnd &&
    css`
      ${media.desktop`grid-column-end: ${props => props.gridColumnEnd}`};
    `}

    ${({ variant }) =>
    variant === 'raised' &&
    css`
      padding: ${({ theme }) => theme.spacing()};
      box-shadow: 0 2px 24px rgba(0, 0, 0, 0.24);
      border: 0;

      ${media.tablet`padding: ${({ theme }) => theme.spacing(2)}`};
    `}

    ${({ variant }) =>
    variant === 'subtle' &&
    css`
      box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.separator};
      border: 0;
    `}

    ${({ hasLink }) =>
    hasLink &&
    css`
      box-shadow: 0 2px 4px ${({ theme }) => theme.boxShadowColor};
      cursor: pointer;
      &:hover {
        box-shadow: 0 4px 8px ${({ theme }) => theme.boxShadowColorHover};
      }
    `}

  ${({ variant }) =>
    variant === 'transparent' &&
    css`
      background-color: transparent;
    `}

    ${({ overflow }) =>
    overflow &&
    css`
      overflow: visible;
    `}
`;

export const CardContent = styled.div`
  padding: ${({ theme }) => theme.spacing()};
  ${props =>
    props.variant === 'padded' &&
    css`
      padding: ${({ theme }) => theme.spacing()};

      ${media.tablet`padding: ${({ theme }) => theme.spacing(2)}`};
    `}
  ${props =>
    props.variant === 'semiPadded' &&
    css`
      padding: ${({ theme }) => theme.spacing()};
    `}
    ${props =>
    props.alignment === 'center' &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `};
`;

export const CardTitle = styled.h3`
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
`;

export const CardSubtitle = styled.p`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  ${fontSize.body}
  color: ${({ theme }) => theme.textLight};

  ${({ variant }) =>
    variant === 'auth' &&
    css`
      text-align: center;
    `}
`;

export const CardDescription = styled.p`
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  margin-bottom: 0;

  ${({ variant }) =>
    variant === 'oneLiner' &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardHeader = styled.div`
  position: relative;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
  background-size: cover;
  background-position: center center;

  ${
    '' /* + ${CardContent} {
    border-top: 1px solid ${({ theme }) => theme.separator};
  } */
  }

  ${props =>
    props.hasMinimumHeight &&
    css`
      height: 100px;
    `}

  ${props =>
    props.variant === 'centered' &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}

    ${props =>
    props.variant === 'message' &&
    css`
      padding: ${({ theme }) => theme.spacing()};
      display: flex;
      justify-content: space-between;
    `}
`;

export const CardFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing()};
`;

export const ArticleCardCountry = styled.p`
  ${fontSize.body}
  color: ${({ theme }) => theme.textLight};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  margin-top: 0;
`;

export const ArticleCardTitle = styled.h3`
  margin: 0;
  ${fontSize.body}
  font-weight: bold;
  line-height: 1.125;
`;

export const ArticleCardTime = styled.time`
  color: ${({ theme }) => theme.textLight};
`;
