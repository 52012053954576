import { post, get, put } from './';
import { CLIENT_ID } from '../constants';

export const signIn = payload =>
  post(`/api/v1/oauth/token`, {
    ...payload,
    clientId: CLIENT_ID,
    grantType: 'password',
  });

export const signup = payload => post(`/api/v1/users`, payload);

export const refreshToken = payload =>
  post(`/api/v1/oauth/token`, {
    ...payload,
    clientId: CLIENT_ID,
    grantType: 'refresh_token',
  });

export const postForgotPassword = payload => post(`/api/v1/passwords`, payload);

export const updatePassword = (confirmationToken, payload) =>
  put(`/api/v1/passwords/${confirmationToken}`, {
    ...payload,
    clientId: CLIENT_ID,
  });

export const getMe = () => get(`/api/v1/me`);

export const updateMe = payload => put(`/api/v1/me`, payload);
