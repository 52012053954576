import { useState, useEffect } from 'react';
import { AuthContext } from 'contexts';
import { getMe } from 'api/auth';
import { useWidget } from 'hooks';

const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken'),
  );
  const { onReset } = useWidget();
  const [user, setUser] = useState({});

  const handleUpdate = ({ accessToken, refreshToken }) => {
    setAccessToken(accessToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  const handleReset = () => {
    setAccessToken(null);
    setUser({});
    localStorage.setItem('accessToken', '');
    localStorage.removeItem('draft');
    localStorage.setItem('refreshToken', '');
  };

  const handleGetUser = async () => {
    try {
      const { data } = await getMe();
      setUser(data);
    } catch (err) {}
  };

  useEffect(() => {
    if (accessToken) {
      handleGetUser();
    } else {
      onReset();
    }

    // eslint-disable-next-line
  }, [accessToken]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        user,
        setUser,
        handleGetUser,
        onReset: handleReset,
        onUpdate: handleUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
